export default {
  "locale": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])}
  },
  "introduction": {
    "shortIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am a first year bachelor of science in nursing student at the Amsterdam University of Applied Sciences."])},
    "shortIntro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out my projects and work experience below."])}
  },
  "aboutMeInCode": {
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> Nigel.skills"])},
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> Nigel.education"])},
    "resume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> Nigel.resume"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> Nigel.contactInfo"])},
    "interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> Nigel.interests"])},
    "hire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hire"])},
    "nigelResumeNPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigel/resume"])},
    "resumePdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume.pdf"])},
    "outputSkills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[\"Java\", \"Vue\", \"Javascript\", \"HTML\", \"CSS\", \"Typescript\", \"MySQL\", \"NoSQL\", \"Cypress\"]"])},
    "outputInterests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[\"Airsoft\", \"Motorcycle riding\", \"Gaming\"]"])},
    "outputEducation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"First year bachelor of science in nursing at the Amsterdam University of Applied Sciences.\""])}
  },
  "projects": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "linkToWebsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to website"])},
    "githubRepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Github Repository"])},
    "agileBot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agile Discord bot"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Discord bot that also has a web dashboard."])}
    },
    "agileBotWeb": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agile Discord bot web dashboard"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A web dashboard for the Agile Discord bot, i use to experiment with social login, OAUTH2 and the discord api in combination with the bot."])}
    },
    "discordApiNPM": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord API NPM"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A NPM package that allows you to easily interact with the Discord API."])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published as NPM package."])},
      "npmPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPM page"])}
    },
    "portfolioWebsite": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website!"])}
    },
    "weatherApp": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weather app"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A weather app that uses the WeatherAPI and Tomorrow.io API made with Vite and Spring Boot. I made this app to learn to interact with APIs and to learn how to use Vite. I also wanted to use a Spring Boot backend instead of doing the requests directly in vue."])},
      "webpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to webpage"])}
    },
    "PEWA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Enterprise web application"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a project i did with a team for a grade at school."])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a web application for the municipality of Amsterdam."])},
      "description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is made with 5 people including me."])},
      "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unavailable"])}
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact me!"])}
  },
  "workExperience": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Experience"])},
    "skills": {
      "collaborate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborate"])},
      "1-on-1 interviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-on-1 interviews"])},
      "presenting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presenting"])},
      "speaking in front of groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speaking in front of groups"])},
      "leadership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leadership"])},
      "training staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training staff"])},
      "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "customer satisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer satisfaction"])},
      "performing under pressure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performing under pressure"])},
      "problem solving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem solving"])},
      "stressResistant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stress resistant"])},
      "integrity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrity"])},
      "disciplined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discipline"])}
    },
    "picnic": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Runner Trainer"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picnic"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a Runner Trainer, I am part of the application process for new candidates who show interest in working as a Runner at Picnic. I am responsible for the selection and training of these candidates by having job interviews, giving presentations and of course providing practical training. In addition, I have also given tours for the fire department and people from Groupil."])},
      "timeAtCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 2023 - Present"])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Runner"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a runner, I am responsible for delivering groceries to customers. This has to be done on a fairly tight schedule, but you do take enough time to leave a good impression with the customers. Furthermore, you ensure that your car is loaded and departs on time."])},
      "timeAtCompany2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September 2022 - Present"])}
    },
    "defensie": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserves Soldier (IT)"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Army - C2OstCo"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a Reserves soldier C2OstCo, I support the installation, operation and maintenance of all Communication and Information Systems in use in the Army. Think of computer networks and radio satellite communication. You do this part-time and is mainly in the evening and on the weekend."])},
      "timeAtCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April 2024 - Present"])}
    },
    "RYSST": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junior Software Developer"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RYSST"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a Junior Software Developer at RYSST, I am responsible for developing software for clients. This can vary from creating websites to creating software for companies. I work here with a small team of developers and designers."])},
      "timeAtCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March 2024 - Present"])}
    }
  }
}